import { useEffect } from "react";

import { OptionCheckboxesProps } from "./types";

export default function useSelectionFilter<T>(props: OptionCheckboxesProps<T>) {
  // Leave the other properties on the props object so they can be used to infer each other's types
  const { selection, question, onChange } = props;

  useEffect(() => {
    if (selection) {
      const newSelection = selection.filter((id) => {
        // An option in a non-deleted group is fine.
        for (const group of question.optionGroups ?? []) {
          for (const option of group.options) {
            if (option.id === id) return true;
          }
        }
        // If it's not a deleted option either, hide it.
        if (!question.deletedOptions?.some((option) => option.id === id)) return false;
        // If "show deleted" is true then any deleted option is fine.
        if (props.showDeleted) return true;
        // If there are no deletedOptionsToShow, hide everything else.
        if (!props.deletedOptionsToShow) return false;
        // Otherwise, anything in deletedOptionsToShow is fine.
        return props.deletedOptionsToShow.includes(id);
      });
      if (newSelection.length < selection.length) onChange(newSelection);
    }
  }, [onChange, question, selection, props.showDeleted, props.deletedOptionsToShow]);
}
