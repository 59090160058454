import React, { useEffect, useMemo } from "react";

import { AnalyticsCode, FormCategory, QuestionType } from "../../../../../../__generated__/graphql";
import InputLabel from "../../../../../../shared/components/design-system/InputLabel";
import { FilterSettings } from "../../../../hooks/filtering/useFilterFromUrlParams";
import { TimePeriodSettings } from "../../../../hooks/filtering/useTimePeriodFromUrlParams";
import useOrg from "../../../../hooks/useOrg";
import useReportFields, { Column } from "../../../../hooks/useReportFields";
import FilterChips from "../../../design-system/FilterChips";
import FilterPanel from "../../../design-system/FilterPanel";
import DateFilter from "../../DateFilter";
import { SplitSettings } from "../useUrlSplitDimension";
import QuestionSelect from "./QuestionSelect";
import GroupingButton from "./grouping/GroupingButton";
import styles from "./index.module.scss";

export default function ChartSelectorControls({
  className,
  firstSplit,
  secondSplit,
  filterSettings,
  datePickerSettings,
  category,
}: {
  className?: string;
  firstSplit: SplitSettings;
  secondSplit: SplitSettings;
  filterSettings: FilterSettings;
  datePickerSettings: TimePeriodSettings | null;
  category: FormCategory;
}) {
  const defaultPanels = useDefaultPanels(firstSplit, secondSplit);
  const singleAnswer = useSingleAnswer(firstSplit);

  useEffect(() => {
    if (firstSplit.analyticsCode !== secondSplit.analyticsCode) return;
    if (firstSplit.questionId !== secondSplit.questionId) return;
    if (singleAnswer) secondSplit.clear();
  }, [singleAnswer, firstSplit, secondSplit]);

  return (
    <div className={`${styles.ChartSelectionPanel} ${className ?? ""}`}>
      <QuestionSelect
        settings={firstSplit}
        label="Report question"
        className={styles.ChartSelectionPanel__analysisCode}
        trackingName="question"
        category={category}
      />
      <QuestionSelect
        settings={secondSplit}
        label="Split data"
        className={styles.ChartSelectionPanel__segmentBy}
        trackingName="split data by"
        exclude={singleAnswer ? [firstSplit] : undefined}
        category={category}
      />

      <div className={styles.ChartSelectionPanel__dateRange}>
        {datePickerSettings ? (
          <>
            <InputLabel>Date range</InputLabel>
            <DateFilter
              datePickerSettings={datePickerSettings}
              className={styles.ChartSelectionPanel__dateRangePicker}
            />
          </>
        ) : null}
      </div>

      <FilterPanel
        defaultPanels={defaultPanels}
        settings={filterSettings}
        className={styles.ChartSelectionPanel__filterButton}
        category={category}
      />

      <GroupingButton
        firstSplit={firstSplit}
        secondSplit={secondSplit}
        className={styles.ChartSelectionPanel__groupButton}
        filter={filterSettings.filter}
      />

      <FilterChips className={styles.ChartSelectionPanel__filterChips} settings={filterSettings} />
    </div>
  );
}

// Get the default filter panels — that is, anything we're splitting by, in order, deduped
function useDefaultPanels(firstSplit: SplitSettings, secondSplit: SplitSettings) {
  const availableFilters = useReportFields();
  return useMemo(() => {
    const defaultPanels: Column[] = [];

    function add(col: Column | null | undefined) {
      if (!col || defaultPanels.some((c) => c.id === col.id)) return;
      defaultPanels.push(col);
    }
    function addByQuestionId(questionId: string | null) {
      if (!questionId) return;
      add(availableFilters.find((col) => col.question?.id === questionId));
    }
    function addByAnalyticsCode(analyticsCode: AnalyticsCode | null) {
      if (!analyticsCode) return;
      for (const col of availableFilters) {
        if (col.analyticsCode === analyticsCode) defaultPanels.push(col);
      }
    }

    addByQuestionId(firstSplit.questionId);
    addByAnalyticsCode(firstSplit.analyticsCode);
    addByQuestionId(secondSplit.questionId);
    addByAnalyticsCode(secondSplit.analyticsCode);
    return defaultPanels;
  }, [
    availableFilters,
    firstSplit.analyticsCode,
    firstSplit.questionId,
    secondSplit.analyticsCode,
    secondSplit.questionId,
  ]);
}

/** Returns true if the first split is one that can only ever have one answer, and false if it could have multiple (or if anything unexpected happens) */
function useSingleAnswer(firstSplit: SplitSettings): boolean {
  const { currentOrg } = useOrg();

  if (firstSplit.analyticsCode === AnalyticsCode.Form) return true;

  // We don't actually use status here but we might as well return the right answer all the same
  if (firstSplit.analyticsCode === AnalyticsCode.Status) return true;

  if (firstSplit.analyticsCode) {
    const questions = currentOrg.questions.filter((q) => q.analyticsCode === firstSplit.analyticsCode);
    // We can't enforce that there's only one answer if there are two questions
    if (questions.length !== 1) return false;
    return questions[0].type === QuestionType.Radio;
  }

  if (firstSplit.questionId) {
    const question = currentOrg.questions.find((q) => q.analyticsCode === firstSplit.analyticsCode);
    if (!question) return false;
    return question.type === QuestionType.Radio;
  }

  // It's probably something like outcome, which you can have multiple of
  return false;
}
