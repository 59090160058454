import React, {
  PropsWithChildren,
  SyntheticEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { createPortal } from "react-dom";

import useGeneratedId from "../../../hooks/useGeneratedId";
import useOnParentClose from "../tabs/closable";
import styles from "./styles.module.scss";

/*
This component assumes that modals will open and close, mount and unmount, but not move around. It also assumes that modals will be triggered by user interaction only, which means that if two modals are open simultaneously, one must be a child of the other in the React component tree. This means when a modal closes, we know its parent will get focus
*/

/** If this is set then the modal is not permitted to have children */
const modalLeafContext = createContext<boolean>(false);

export interface ModalPropsBase {
  isOpen: boolean;
  /** Code that closes the modal and does nothing else. If you need a confirm box or whateverr, use onClickClose. */
  onClose?: () => void;
  /** Code to run when the close button is clicked, if different to onClose. This should call onClose (assuming the closure isn't cancelled for some reason) */
  onClickClose?: () => void;
  /** If set, the modal will treat clicking outside the modal the same as clicking the "close" button */
  closeOnClickOutside?: boolean;
  /** A flag that sets an Aria tag to say "this is an alert box rather than just a regular modal dialog" */
  alert?: boolean;
}

/** A wrapper that makes something modal. Doesn't render any actual content, just creates a context for things to live in. */
export default function Modal({
  children,
  isOpen,
  dimBackground = false,
  onClose,
  onClickClose,
  closeOnClickOutside,
  alert,
  className = "",
  labelledBy,
  labelRef,
  isLeaf,
}: PropsWithChildren<
  ModalPropsBase & {
    dimBackground?: boolean;
    closeOnClickOutside?: boolean;
    className?: string;
    labelledBy: string;
    labelRef: React.RefObject<HTMLElement>;
    /** If this is set then the modal is not permitted to have children */
    isLeaf: boolean;
  }
>) {
  const id = useGeneratedId();

  useOnParentClose(onClose);

  const withinLeaf = useContext(modalLeafContext);

  if (withinLeaf) {
    // If you are getting this error, it usually this means you are trying to put a dialog box inside some smaller modal container such as a pop-up menu, and the correct solution is to place the dialog outside the menu and have the menu activate it. This is because the system that activates and deactivates modals assumes that they follow the React tree — meaning you can't have an open modal inside a closed modal any more than you can have a visible div inside a hidden one.
    throw new Error("You cannot create a modal in this position");
  }

  const onCancel = useCallback(
    (event: SyntheticEvent<HTMLDialogElement, Event>) => {
      (onClickClose ?? onClose)!();
      event.preventDefault();
    },
    [onClickClose, onClose],
  );

  const dialog = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    if (!dialog.current) return;
    if (isOpen && !dialog.current.open) {
      dialog.current.showModal();
      labelRef.current?.focus();
    }
    if (!isOpen && dialog.current.open) {
      dialog.current.close();
    }
  }, [isOpen, labelRef]);

  return createPortal(
    <dialog
      id={id}
      className={styles.container}
      onSubmit={swallowEvent}
      onPaste={swallowEvent}
      ref={dialog}
      role={alert ? "alertdialog" : "dialog"}
      aria-labelledby={labelledBy}
      onCancel={onCancel}
    >
      <div
        className={`
          ${styles.cover}
          ${dimBackground ? styles.dimBackground : ""}
        `}
        onClick={closeOnClickOutside ? onClickClose ?? onClose : undefined}
      />
      <div className={className}>
        <modalLeafContext.Provider value={isLeaf}>{children}</modalLeafContext.Provider>
      </div>
    </dialog>,
    (document.getElementById("modal-container") ??
      (process.env.NODE_ENV === "test" ? document.createElement("div") : null))!,
  );
}

/** Prevent events from bubbling out of modals and triggering forms outside the modal */
function swallowEvent(ev: SyntheticEvent) {
  ev.stopPropagation();
}
