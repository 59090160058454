import classNames from "classnames";
import React, { PropsWithChildren, ReactNode, useEffect, useRef } from "react";

import Icon from "../../../../../shared/components/design-system/Icon";
import useGeneratedId from "../../../../../shared/hooks/useGeneratedId";
import styles from "./styles.module.scss";

export interface RadioOption<T extends string> {
  label: ReactNode;
  id: T;
  invalid?: string | null;
  disabled?: boolean;
}

type Variant = "horizontal" | "vertical" | "segmented" | "tabs" | "panels";

export default function RadioButtons<T extends string>({
  label,
  visuallyHideLabel,
  options,
  value,
  onChange,
  variant,
  className,
  disabled,
  required,
}: {
  label: ReactNode;
  visuallyHideLabel?: boolean;
  options: Readonly<RadioOption<T>[]>;
  value: T | null;
  onChange: (value: T) => void;
  variant?: Variant;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}) {
  const groupId = useGeneratedId();

  const classes = classNames(
    {
      [styles.RadioGroup]: !variant || variant === "vertical" || variant === "horizontal",
      [styles.Disabled]: disabled,
      [styles.Horizontal]: variant === "horizontal",
      [styles.Vertical]: !variant || variant === "vertical",
      [styles.SegmentedControl]: variant === "segmented",
      [styles.Panels]: variant === "panels",
      [styles.Tabs]: variant === "tabs",
    },
    className ?? null,
  );

  return (
    <fieldset className={classes}>
      <legend className={visuallyHideLabel ? styles.Hidden : ""}>{label}</legend>
      <div className={styles.Options}>
        {options.map(({ label, id, ...option }) => (
          <RadioButton
            key={id}
            groupId={groupId}
            {...option}
            checked={id === value}
            onClick={() => onChange(id)}
            required={required}
            variant={variant}
            disabled={disabled}
          >
            {label}
          </RadioButton>
        ))}
      </div>
    </fieldset>
  );
}

export function RadioButton({
  groupId,
  children,
  invalid,
  disabled,
  required,
  checked,
  onClick,
  variant,
  className,
}: PropsWithChildren<{
  groupId?: string;
  invalid?: string | null;
  disabled?: boolean;
  checked: boolean;
  required?: boolean;
  onClick: () => void;
  variant?: Variant;
  className?: string;
}>) {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => ref.current?.setCustomValidity(invalid ?? ""), [invalid]);

  const classes = classNames(
    {
      [styles.Disabled]: disabled,
      [styles.Checked]: checked,
      [styles.RadioButton]: !variant || variant === "vertical" || variant === "horizontal",
      [styles.Segment]: variant === "segmented",
      [styles.Tab]: variant === "tabs",
    },
    className ?? null,
  );

  return (
    <label className={classes}>
      <input
        name={groupId}
        type="radio"
        checked={checked}
        onChange={onClick}
        ref={ref}
        disabled={disabled}
        required={required}
      />
      <span className={variant === "panels" ? styles.Panel : ""}>
        <span>{children}</span>
        {variant === "panels" && checked ? (
          <Icon className="ds-ml-5 ds-text-violet-500" size={24} icon="circleCheck" />
        ) : null}
      </span>
    </label>
  );
}

export function RadioPanelContents({ title, body }: { title: string; body: string }) {
  return (
    <>
      <span className={styles.Title}>{title}</span>
      <p>{body}</p>
    </>
  );
}
