import mixpanel from "mixpanel-browser";
import React, { FunctionComponent, useEffect } from "react";

import StopSign from "../../assets/images/EmptyStateAndUpsell/stop-sign.svg";
import Container, { ContainerSize } from "../design-system/Container";
import EmptyStateBanner from "../design-system/EmptyStateBanner";

const NotFoundPage: FunctionComponent = () => {
  useEffect(() => {
    document.title = "Culture Shift | Not Found";
    mixpanel.track("Visited Not Found page");
  }, []);

  return (
    <Container size={ContainerSize.Small} className="ds-my-9">
      <NotFoundMessage />
    </Container>
  );
};

export const NotFoundMessage = () => (
  <EmptyStateBanner
    direction="vertical"
    title="Page not found"
    body="The content you were looking for could not be found."
    graphic={StopSign}
    internalLink="/"
    buttonLabel="Return to the homepage"
  />
);

export default NotFoundPage;
